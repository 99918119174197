const moment = require('moment');
const Highcharts = require('highcharts');

export default {
  props: {
    graph: Object,
    duration: String
  },
  data() {
    return {
      barChartOptions: null,
      startTime: null,
      endTime: null,
      groupByDuration: null,
      graphData: null
    };
  },
  watch: {
    duration: {
      handler: function () {
        this.drawBarGraph(this.graph);
      }
    }
  },
  methods: {
    drawBarGraph(graph) {
      try {
        this.graphData = graph.data;

        // this.graphData = null;
        if (this.graphData === null) {
          this.barChartOptions = null;
          return;
        }
        if (graph.durationType == 'exact') {
          this.startTime = moment(graph.startTime);
          this.endTime = moment(graph.endTime);
        } else if (graph.durationType == 'latest') {
          this.timingFilter = graph.duration;
          this.startTime = moment().subtract(this.timingFilter, 'minutes');
          this.endTime = moment();
        } else {
          throw new Error('Invalid duration type.');
        }
        this.groupByDuration = graph.durationGroup;

        // From graph data, build list of labels
        let categories = Object.keys(this.graphData);
        // Build datasets for label array
        let datasets = [];
        for (var i = 0; i < categories.length; i++) {
          // Build data
          let dataList = [];
          for (var j = 0; j < categories.length; j++) {
            if (i == j) {
              dataList.push(this.graphData[categories[i]]);
            } else {
              dataList.push(0);
            }
          }

          datasets.push({
            name: categories[i],
            data: dataList
          });
        }

        this.barChartOptions = {
          chart: {
            type: 'bar',
            height: 200
          },
          title: {
            text: null
          },
          xAxis: {
            type: 'category',
            categories: categories
          },
          yAxis: {
            min: 0,
            title: {
              text: null
            },
            stackLabels: {
              style: {
                color: '#555'
              },
              enabled: true
            }
          },
          legend: {
            enabled: false
          },
          tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{point.y}'
          },
          plotOptions: {
            column: {
              // stacking: "false",
              dataLabels: {
                enabled: false,
                color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
              }
            }
          },
          series: datasets
        };
      } catch (e) {
        this.reportError(e);
        this.errorToas('Something is wrong, please contact support.');
      }
    }
  },

  mounted() {
    this.drawBarGraph(this.graph);
  }
};
