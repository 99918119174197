import retnetionModule from '../../services/retention';
import dashboardService from '../../services/dashboard';
import FiltersMixin from './../../mixins/filtersMixins';
import ChartPageSelector from './../../components/chartPageSelector/chartPageSelector';
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import RetentionStepsFilter from '@/components/chartFilters/retentionStepsFilter/retentionStepsFilter';
//import RetentionGraph from "@/components/graphs/retentionGraph/retentionGraph";
import DynamicTimelineGraphComponent from '@/components/graphs/dynamicTimelineGraph/dynamicTimelineGraph';
import HorizontalBarGraph from '@/components/graphs/horizontalBarGraph/horizontalBarGraph';
import DynamicBarGraph from '@/components/graphs/dynamicBarGraph/dynamicBarGraph';
import ErrorBlock from '@/components/500Error';
import moment from 'moment';
import DashboardSelectorComponent from '@/pages/dashboardSelectorPopup/dashboardSelector';
import CohortGraph from '@/components/graphs/cohortGraph/cohortGraph';
import CohortTable from '@/components/graphs/cohortGraph/cohortTable';

// var fileDownload = require('js-file-download');

export default {
  name: 'home',
  mixins: [FiltersMixin],

  components: {
    ChartPageSelector,
    RetentionStepsFilter,
    UserPropertyFilterComponent,
    //RetentionGraph,
    DynamicTimelineGraphComponent,
    HorizontalBarGraph,
    DynamicBarGraph,
    ErrorBlock,
    DashboardSelectorComponent,
    CohortGraph,
    CohortTable
  },
  data() {
    return {
      fetchingGraphData: false,
      showErrorBlock: false,

      retentionData: null,

      form: {
        metric: 'percentage',
        groupDataBy: 'month',
        timingFilter: 'last-180',
        customTimeRange: null
      },
      apiParams: null,

      // Timing properties
      timingOptions: [
        {
          title: 'Last 7 Days',
          value: 'last-7'
        },
        {
          title: 'Last 15 Days',
          value: 'last-15'
        },
        {
          title: 'Last 30 Days',
          value: 'last-30'
        },
        {
          title: 'Last 60 Days (2 Months)',
          value: 'last-60'
        },
        {
          title: 'Last 90 Days (3 Months)',
          value: 'last-90'
        },
        {
          title: 'Last 180 Days (6 Months)',
          value: 'last-180'
        },
        {
          title: 'Last 365 Days (1 Year)',
          value: 'last-365'
        },
        {
          value: 'custom',
          title: 'Custom Range'
        }
      ],

      dashboardCardPopup: {
        cardType: 'cohort_graph',
        cardParams: null
      }
    };
  },
  methods: {
    async fetchRetentionData(isPageLoad) {
      try {
        // Read Start & Return Event
        let retentionSteps = this.$refs.retentionStepsRef.getProcessedFilters();
        let startEvent = retentionSteps.startEvent;
        let returnEvent = retentionSteps.returnEvent;
        let onlyFirstTimeEvent = retentionSteps.onlyFirstTime;
        // let userFilters = this.$refs.userFilterComponent.getFilters();

        console.log('start,end evnet', retentionSteps);
        if (!returnEvent || !startEvent) {
          this.warningToast('Please select start and return events!');
          return;
        }

        // Build timing filters
        if (this.form.timingFilter == 'custom') {
          if (this.form.customTimeRange == null) {
            this.warningToast('Please specify report duration!');
            return;
          }

          let startDate = moment(this.form.customTimeRange[0]);
          let endDate = moment(this.form.customTimeRange[1]);
          let days = endDate.diff(startDate, 'days');

          if (days > 712) {
            this.warningToast('Report duration can not exceed more than a year (712 days)! ');
            return;
          }

          if (days > 60 && this.form.groupDataBy == 'day') {
            this.form.groupDataBy = 'week';
          }
        } else {
          let days = parseInt(this.form.timingFilter.replace('last-', ''));
          if (days > 60 && this.form.groupDataBy == 'day') {
            this.form.groupDataBy = 'week';
          }
        }

        this.fetchingGraphData = true;
        this.showErrorBlock = false;

        // Modify Query-String
        this.apiParams = {
          form: this.form,
          startEvent: startEvent,
          returnEvent: returnEvent,
          onlyFirstTimeEvent: onlyFirstTimeEvent
        };
        if (!isPageLoad) {
          let queryParams = await dashboardService.encryptQuery({
            query: JSON.stringify(this.apiParams)
          });
          const query = Object.assign({}, this.$route.query);
          query.retentionQuery = queryParams.data;
          console.log('retention query', query.retentionQuery);
          this.$router.push({ query });
        }

        //Build params
        let params = {
          returnEvent: returnEvent,
          startEvent: startEvent,
          onlyFirstTimeEvent: onlyFirstTimeEvent,

          timingFilter: this.form.timingFilter,
          customTimeRange: this.form.customTimeRange,

          groupDataBy: this.form.groupDataBy
        };

        // Send request to server
        let result = await retnetionModule.getRetentionData(params);
        console.log('result is', result);

        this.retentionData = result.data;

        this.fetchingGraphData = false;
      } catch (err) {
        this.fetchingGraphData = false;
        this.showErrorBlock = true;
        this.reportError(err);
      }
    },

    async exportReport() {
      this.fetchingGraphData = true;
      try {
        let result = await retnetionModule.exportReport({
          params: this.apiParams,
          data: this.retentionData
        });

        if (result.data.success) {
          window.open(result.data.url, '_blank');
        } else {
          this.errorToast('Failed to export report. Please contact support!');
          this.reportError('FAILED TO EXPORT COHORT REPOT:' + JSON.stringify(result.data));
        }
      } catch (err) {
        this.errorToast('Failed to export report. Please contact support!');
        this.reportError(err);
      } finally {
        this.fetchingGraphData = false;
      }
    },

    //#region ----------- Add Card To Dashboard ------------------

    showSelectDashboardPopup(cardType) {
      this.dashboardCardPopup.cardType = cardType;
      this.dashboardCardPopup.cardParams = this.$route.query.retentionQuery;
      console.log('popup params are', this.dashboardCardPopup);

      this.$refs.dashboardSelector.dialogFormVisible = true;
    }
  },

  async mounted() {
    if (this.$route.query.retentionQuery) {
      let result = await dashboardService.decryptQuery({ query: this.$route.query.retentionQuery });
      let data = result.data;
      this.form = this.overrideJson(this.form, data.form);

      this.$refs.retentionStepsRef.renderExistingFilters(data.startEvent, data.returnEvent, data.onlyFirstTimeEvent);
      requestAnimationFrame(() => {
        this.fetchRetentionData(true);
      });
    }
  }
};
