<template>
  <div class="w-full bg-white border rounded-md overflow-hidden text-center py-12">
    <img class="mx-auto" style="width: 30%; opacity: 0.5" src="@/assets/images/500-error.png" />

    <!-- Message  -->
    <div class="text-gray-400 text-2xl font-bold mt-3">{{ message }}</div>

    <!-- Description -->
    <div class="text-gray-400 text-sm mt-2 mb-2">{{ description }}</div>
  </div>
</template>

<script>
export default {
  props: {
    message: { required: false, default: `Something's not right!` },
    description: { required: false, default: 'Please try again in some time!' }
  },
  data() {
    return {};
  },
  methonds: {}
};
</script>
