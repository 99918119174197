const axios = require('axios');
const { analyticsUrl, buildAuthHeaders } = require('./settings.js');

exports.getRetentionData = function (params) {
  return axios.post(analyticsUrl + `cohort`, params, {
    headers: buildAuthHeaders()
  });
};

exports.exportReport = function (params) {
  return axios.post(analyticsUrl + `cohort/export`, params, {
    headers: buildAuthHeaders()
  });
};
