<template>
  <div v-if="!fetchingData">
    <div>
      <!-- Starting Event -->
      <div class="border-b px-4 pt-4 pb-4 position-relative">
        <div class="text-xs pb-1 text-gray-500">Select Start Event</div>

        <!-- First Time Repeat Event CheckBox -->
        <div class="position-absolute right-4">
          <el-checkbox v-model="onlyFirstTime">Consider Only First Time Event</el-checkbox>
          <el-tooltip class="item" effect="dark" content="" placement="top-start">
            <div slot="content">
              If selected, only first event occurence will be counted. <br />
              <div style="margin-top: 10px; line-height: 18px">
                <strong>Example:</strong> <br />For purchase event, customers who are doing <br />
                purchase for first time will only be considered.
              </div>
            </div>
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>

        <!-- IF no start event selected -->
        <el-select class="addNewEventBtn" size="mini" v-model="startEventName" filterable @change="onSelectStartEvent()" placeholder="Add Retention Step" v-if="startEvent == startEventName">
          <el-option-group v-for="eventGroup in eventList" :key="eventGroup.label" :label="eventGroup.label">
            <el-option v-for="eventName in eventGroup.options" :key="eventName" :label="eventName" :value="eventName"> </el-option>
          </el-option-group>
        </el-select>

        <!-- Start Event Filters -->
        <div v-else>
          <!-- Header -->
          <div class="filterHeader">
            <el-select size="mini" v-model="startEventName" filterable @change="onSelectStartEvent()" placeholder="Add Retention Step">
              <el-option-group v-for="eventGroup in eventList" :key="eventGroup.label" :label="eventGroup.label">
                <el-option v-for="eventName in eventGroup.options" :key="eventName" :label="eventName" :value="eventName"> </el-option>
              </el-option-group>
            </el-select>

            <el-button class="addFilterButton" icon="el-icon-plus" type="info" plain size="mini" @click="addPropertyFilter(startEvent)">Add Filter</el-button>
          </div>

          <!-- Filter Of Particular Event -->
          <div v-if="startEvent.filters.length > 0">
            <div class="mt-2.5" v-for="(subFilter, index1) in startEvent.filters" :key="index1">
              <div class="text-gray-500" style="display: inline-block; width: 66px; text-align: right; padding-right: 5px; font-size: 12px; line-height: 26px"><span v-if="index1 != 0">and &nbsp;</span><span v-else>matching</span></div>

              <!-- Select Filter Property -->
              <el-select class="inline" size="mini" v-model="subFilter.propertyName" filterable @change="onFilterPropertyChange(subFilter, startEvent.properties)" placeholder="Select Attribute">
                <el-option v-for="(property, index) in startEvent.properties" :key="index" :label="property.name" :value="property.name"></el-option>
              </el-select>

              <!-- Select Comparision Type -->
              <el-select class="inline" size="mini" v-if="subFilter.comparisionTypeList" v-model="subFilter.comparisionType" filterable placeholder="Select Event Property">
                <el-option v-for="(compType, index2) in subFilter.comparisionTypeList" :key="index2" :label="compType.label" :value="compType.key"></el-option>
              </el-select>

              <!-- Input Field List Based on types -->
              <template class="inline border w-60 w-max-60 overflow-hidden" v-if="subFilter.comparisionType">
                <!-- Render Date For Date Type -->
                <template v-if="subFilter.propertyInfo.type == 'date'">
                  <el-date-picker size="mini" v-model="subFilter.value" type="datetime" placeholder="Select date and time"> </el-date-picker>
                </template>

                <!-- Render Number input for number type -->
                <template v-if="subFilter.propertyInfo.type == 'decimal' || subFilter.propertyInfo.type == 'number' || subFilter.propertyInfo.type == 'bigint'">
                  <el-input size="mini" placeholder="Enter value" v-model="subFilter.value" style="width: 300px !important"></el-input>
                </template>

                <!-- Render Searchable String for string type  -->
                <template v-if="subFilter.propertyInfo.type == 'string'">
                  <el-autocomplete autocomplete="off" size="mini" style="width: 300px !important" placeholder="Enter search term" @focus="setCurrentEventData(startEvent.name, subFilter)" v-model="subFilter.value" :fetch-suggestions="fetchEventPropertySuggestion" clearable></el-autocomplete>
                </template>

                <!-- Boolean - Yes/No dropdown -->
                <template v-if="subFilter.propertyInfo.type == 'boolean'">
                  <el-select size="mini" v-model="subFilter.value" style="width: 300px !important">
                    <el-option label="True/Yes" value="true"></el-option>
                    <el-option label="False/No" value="false"></el-option>
                  </el-select>
                </template>
              </template>

              <!-- Delete Button -->
              <el-button class="addFilterButton deleteBtn" icon="el-icon-delete" @click="deletePropertyFilter(startEvent.filters, index1)" type="text" size="mini"></el-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Return Event -->
      <div class="px-4 pt-4 pb-3">
        <div class="text-xs pb-1 text-gray-500">Select Return Event</div>

        <!-- IF no return event selected -->
        <el-select class="addNewEventBtn" size="mini" v-model="returnEventName" filterable @change="onSelectReturnEvent()" placeholder="Select Return Event" v-if="returnEvent == returnEventName">
          <el-option-group v-for="eventGroup in eventList" :key="eventGroup.label" :label="eventGroup.label">
            <el-option v-for="eventName in eventGroup.options" :key="eventName" :label="eventName" :value="eventName"> </el-option>
          </el-option-group>
        </el-select>

        <!-- Return Event Filters -->
        <div v-else>
          <!-- Header -->
          <div class="filterHeader">
            <el-select size="mini" v-model="returnEventName" filterable @change="onSelectReturnEvent()">
              <el-option-group v-for="eventGroup in eventList" :key="eventGroup.label" :label="eventGroup.label">
                <el-option v-for="eventName in eventGroup.options" :key="eventName" :label="eventName" :value="eventName"> </el-option>
              </el-option-group>
            </el-select>

            <el-button class="addFilterButton" icon="el-icon-plus" type="info" plain size="mini" @click="addPropertyFilter(returnEvent)">Add Filter</el-button>
          </div>

          <!-- Filter Of Particular Event -->
          <div v-if="returnEvent.filters.length > 0">
            <div class="mt-2.5" v-for="(subFilter, index1) in returnEvent.filters" :key="index1">
              <div class="text-gray-500" style="display: inline-block; width: 66px; text-align: right; padding-right: 5px; font-size: 12px; line-height: 26px"><span v-if="index1 != 0">and &nbsp;</span><span v-else>matching</span></div>

              <!-- Select Filter Property -->
              <el-select class="inline" size="mini" v-model="subFilter.propertyName" filterable @change="onFilterPropertyChange(subFilter, returnEvent.properties)" placeholder="Select Attribute">
                <el-option v-for="(property, index) in returnEvent.properties" :key="index" :label="property.name" :value="property.name"></el-option>
              </el-select>

              <!-- Select Comparision Type -->
              <el-select class="inline" size="mini" v-if="subFilter.comparisionTypeList" v-model="subFilter.comparisionType" filterable placeholder="Select Event Property">
                <el-option v-for="(compType, index2) in subFilter.comparisionTypeList" :key="index2" :label="compType.label" :value="compType.key"></el-option>
              </el-select>

              <!-- Input Field List Based on types -->
              <template class="inline border w-60 w-max-60 overflow-hidden" v-if="subFilter.comparisionType">
                <!-- Render Date For Date Type -->
                <template v-if="subFilter.propertyInfo.type == 'date'">
                  <el-date-picker size="mini" v-model="subFilter.value" type="datetime" placeholder="Select date and time"> </el-date-picker>
                </template>

                <!-- Render Number input for number type -->
                <template v-if="subFilter.propertyInfo.type == 'decimal' || subFilter.propertyInfo.type == 'number' || subFilter.propertyInfo.type == 'bigint'">
                  <el-input size="mini" placeholder="Enter value" v-model="subFilter.value" style="width: 300px !important"></el-input>
                </template>

                <!-- Render Searchable String for string type  -->
                <template v-if="subFilter.propertyInfo.type == 'string'">
                  <el-autocomplete autocomplete="off" size="mini" style="width: 300px !important" placeholder="Enter search term" @focus="setCurrentEventData(returnEvent.name, subFilter)" v-model="subFilter.value" :fetch-suggestions="fetchEventPropertySuggestion" clearable></el-autocomplete>
                </template>

                <!-- Boolean - Yes/No dropdown -->
                <template v-if="subFilter.propertyInfo.type == 'boolean'">
                  <el-select size="mini" v-model="subFilter.value" style="width: 300px !important">
                    <el-option label="True/Yes" value="true"></el-option>
                    <el-option label="False/No" value="false"></el-option>
                  </el-select>
                </template>
              </template>

              <!-- Delete Button -->
              <el-button class="addFilterButton deleteBtn" icon="el-icon-delete" @click="deletePropertyFilter(returnEvent.filters, index1)" type="text" size="mini"></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./retentionStepsFilter.scss"></style>

<script>
import retentionStepsFilterComponent from './retentionStepsFilterComponent';
export default retentionStepsFilterComponent;
</script>
