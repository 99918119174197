import eventModule from '../../../services/event';
import FiltersMixin from '../../../mixins/filtersMixins';
import BeatLoader from '@/components/loaders/BeatLoader.vue';
const _ = require('lodash');

export default {
  name: 'eventFilter',
  props: {},
  components: {
    BeatLoader
  },
  mixins: [FiltersMixin],
  data() {
    return {
      fetchingData: true,

      startEvent: null,
      startEventName: null,
      onlyFirstTime: false,

      returnEvent: null,
      returnEventName: null,

      eventList: null,
      selectedEventList: [],
      eventMetadata: null,
      currentEvent: {
        name: null,
        property: null
      }
    };
  },

  methods: {
    getProcessedFilters() {
      return {
        startEvent: this.startEvent,
        returnEvent: this.returnEvent,
        onlyFirstTime: this.onlyFirstTime
      };
    },

    renderExistingFilters(startEvent, returnEvent, onlyFirstTime) {
      this.startEvent = startEvent;
      this.startEventName = startEvent.name;

      this.returnEvent = returnEvent;
      this.returnEventName = returnEvent.name;

      this.onlyFirstTime = onlyFirstTime;
    },

    //#region ----------------------------- Query Builder methods --------------------

    onSelectStartEvent() {
      this.startEvent = {
        name: this.startEventName,
        properties: this.eventMetadata[this.startEventName],
        filters: []
      };
    },

    onSelectReturnEvent() {
      this.returnEvent = {
        name: this.returnEventName,
        properties: this.eventMetadata[this.returnEventName],
        filters: []
      };
      console.log('return evnet setting to', this.returnEvent);
    },

    //#endregion ------------------- Query Builder methods --------------------

    //#region ----------------------------- Query Builder methods --------------------

    // Fetch event list from server
    fetchEventList() {
      this.fetchingData = true;
      eventModule
        .getEventList(this)
        .then((result) => {
          this.eventList = [
            {
              label: 'Growlytics Events',
              options: this.grwDefaultEventList
            }
          ];

          let list = [],
            listFromServer = Object.keys(result.data.data.eventList);
          for (var i = 0; i < listFromServer.length; i++) {
            if (this.grwDefaultEventList.indexOf(listFromServer[i]) < 0) {
              list.push(listFromServer[i]);
            }
          }
          this.eventList.push({
            label: 'Custom Events',
            options: list
          });

          // Remove all events which are hard coded growlytics events.
          this.fetchingData = false;
          this.eventMetadata = result.data.data.eventList;
        })
        .catch((err) => {
          this.reportError(err);
          this.errorToast('Something went wrong.');
          this.fetchingData = false;
        });
    },

    // set eventname on input focus
    setCurrentEventData(eventName, filter) {
      this.currentEvent.name = eventName;
      this.currentEvent.property = filter.propertyInfo.name;
    },

    // ON +where button click
    addPropertyFilter(event) {
      event.filters.push({
        propertyName: null,
        propertyInfo: null,
        comparisionTypeList: null,
        comparisionType: null,
        value: null
      });
    },

    // delete event from selectedEventList
    deleteEventFilter(index) {
      this.selectedEventList.splice(index, 1);
      if (this.selectedEventList.length == 0) {
        this.currentEvent = {
          name: null,
          property: null
        };
      }
    },

    // ON property dropdown change
    onFilterPropertyChange(subFilter, propertyList) {
      // Read proeprty object from property name
      subFilter.propertyInfo = _.find(propertyList, (o) => {
        return o.name == subFilter.propertyName;
      });

      // Read property's datatype
      let propertyType = subFilter.propertyInfo.type;
      console.log('data type', propertyType);
      subFilter.comparisionTypeList = this.getComparisonsForDatatype(propertyType);
      subFilter.comparisionType = subFilter.comparisionTypeList[0].key;
    },

    // delete property filter from selectedEventList.filters
    deletePropertyFilter(propertyFilterList, index) {
      propertyFilterList.splice(index, 1);
    },

    //#endregion ----------------------------- Query Builder methods --------------------

    // Fetch event property suggestions
    fetchEventPropertySuggestion(queryString, cb) {
      let params = {
        eventName: this.currentEvent.name,
        property: this.currentEvent.property,
        queryString: queryString
      };
      eventModule
        .fetchCustomEventValueSuggetionsList(params)
        .then((result) => {
          if (result.data.success) {
            var links = result.data.data;
            var results = queryString ? links.filter(this.createFilter(queryString)) : links;
            cb(results);
          }
        })
        .catch((e) => {
          cb();
          this.reportError(e);
        });
    },

    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    }
  },
  mounted() {
    this.fetchEventList();
  }
};
