<template>
  <div id="horizonatalBarChart">
    <div class="horizonatalChart" v-if="barChartOptions">
      <highcharts class="eventsChart" :options="barChartOptions"></highcharts>
    </div>
    <div v-else class="noDatafound" style="text-align: center; padding-top: 15vh; opacity: 0.5">
      <!-- <img src="https://static.growlytics.in/dashboard-assets/assets/img/segments_icon.png" style="width: 80px;" /> -->
      <br />
      No data found
    </div>
  </div>
</template>

<style lang="scss" src="./horizontalBarGraph.scss"></style>

<script>
import horizontalBarGraphComponent from './horizontalBarGraphComponent';
export default horizontalBarGraphComponent;
</script>
