<template>
  <div id="batChartGraph">
    <div class="barChart" v-if="barChartOptions">
      <highcharts ref="barGraph" class="eventsChart" :options="barChartOptions"></highcharts>

      <div class="chartPagination">
        <el-pagination small background @current-change="onPageChange" :current-page.sync="pageNo" :page-size="pageSize" layout="prev, pager, next" :total="datasets.length"></el-pagination>
      </div>
    </div>
    <div v-else class="noDatafound" style="text-align: center; padding-top: 15vh; opacity: 0.5">
      <!-- <img src="https://static.growlytics.in/dashboard-assets/assets/img/segments_icon.png" style="width: 80px;" /> -->
      <br />
      No data found
    </div>
  </div>
</template>

<style lang="scss" src="./dynamicBarGraph.scss"></style>

<script>
import DynamicBarGraphComponent from './dynamicBarGraphComponent';
export default DynamicBarGraphComponent;
</script>
