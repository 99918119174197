const moment = require('moment');
const Highcharts = require('highcharts');
const _ = require('lodash');
import settingsModule from '@/services/settings';

export default {
  props: {
    graph: Object,
    duration: String
  },
  data() {
    return {
      barChartOptions: null,
      startTime: null,
      endTime: null,
      groupByDuration: null,
      chartObj: null,

      // Graph Variables
      pageNo: 1,
      pageSize: 10,
      graphData: null,
      categories: null,
      datasets: null
    };
  },
  watch: {
    duration: {
      handler: function () {
        this.drawBarGraph(this.graph);
      }
    }
  },
  methods: {
    prepareDataSets(graph) {
      this.graphData = graph.data;

      // this.graphData = null;
      if (this.graphData === null) {
        this.barChartOptions = null;
        return;
      }

      if (graph.durationType == 'exact') {
        this.startTime = moment(graph.startTime);
        this.endTime = moment(graph.endTime);
      } else if (graph.durationType == 'latest') {
        this.timingFilter = graph.duration;
        this.startTime = moment().subtract(this.timingFilter, 'minutes');
        this.endTime = moment();
      } else {
        throw new Error('Invalid duration type.');
      }
      this.groupByDuration = graph.durationGroup;

      // From graph data, build list of labels
      this.categories = Object.keys(this.graphData);
      // Build datasets for label array
      this.datasets = [];
      for (var i = 0; i < this.categories.length; i++) {
        // Build data
        let dataList = [];
        for (var j = 0; j < this.categories.length; j++) {
          if (i == j) {
            dataList.push(this.graphData[this.categories[i]]);
          } else {
            dataList.push(0);
          }
        }

        this.datasets.push({
          name: this.categories[i],
          // backgroundColor: this.getRandomColor(i),
          // borderColor: this.getRandomColor(i),
          // borderWidth: 1,
          data: dataList
        });
      }
    },

    onPageChange() {
      try {
        if (this.graphData === null) {
          this.barChartOptions = null;
          return;
        }
        let start = (this.pageNo - 1) * this.pageSize;
        let end = start + 10;
        // console.log('pagination', this.pageNo, this.pageSize, this.datasets.length);
        // console.log('strat, end', start, end);
        let categories = JSON.parse(JSON.stringify(this.categories.slice(start, end)));
        // console.log('categories', categories);
        let datasets = JSON.parse(JSON.stringify(this.datasets.slice(start, end)));
        // console.log('datasets', datasets);

        for (let i = 0; i < datasets.length; i++) {
          datasets[i].data = datasets[i].data.slice(start, end);
        }

        let chartOptions = {
          chart: {
            type: 'column',
            height: 200
          },
          title: {
            text: null
          },
          xAxis: {
            type: 'category',
            categories: categories
          },
          yAxis: {
            min: 0,
            title: {
              text: null
            },
            stackLabels: {
              style: {
                color: '#555'
              },
              enabled: true
            }
          },
          legend: {
            enabled: false
          },
          tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{point.y}'
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
                color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
              }
            }
          },
          series: datasets
        };
        if (!this.barChartOptions) {
          this.barChartOptions = chartOptions;
        } else {
          let chart = this.$refs.barGraph.chart;
          while (chart.series.length > 0) {
            chart.series[0].remove(false);
          }
          chart.xAxis[0].setCategories(categories, false);
          for (let i = 0; i < datasets.length; i++) {
            chart.addSeries(datasets[i], false);
          }
          chart.redraw();
        }
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something is wrong, please contact support.');
      }
    },

    drawBarGraph(graph) {
      try {
        this.prepareDataSets(graph);

        this.pageNo = 1;
        this.onPageChange();
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something is wrong, please contact support.');
      }
    },

    getDataAsTable() {
      this.tableData = [];

      // Add proeprties column
      this.tableColumns = [
        {
          headerName: 'Event/Attribute',
          prop: 'name',
          width: '200',
          fiexed: true
        }
      ];

      // Iterate and build rows for property list
      Object.keys(this.graph.data).forEach((propertyName) => {
        this.tableData.push({
          name: propertyName
        });
      });

      // Generate list of date columns from start time to end time
      let currentTime = this.startTime.clone().startOf(this.groupByDuration);
      while (currentTime <= this.endTime) {
        let endTime = currentTime.clone().add(1, this.groupByDuration + 's');

        // Create column for given date
        let headerName = currentTime.clone().format('MMM DD, hh A');
        this.tableColumns.push({
          headerName: headerName,
          prop: headerName,
          width: 150,
          fiexed: false
        });

        // For given date, add value for all table rows
        this.tableData.forEach((row) => {
          let propertyName = row.name;

          //Read count of events for current property and time
          let count = 0;
          let entry = _.find(this.graph.data[propertyName], (xy) => {
            xy.x = moment(xy.x).format('YYYY-MM-DDTHH:mm:ss');
            return xy.x == currentTime.format('YYYY-MM-DDTHH:mm:ss');
          });

          if (entry != null) count = entry.y;

          // Add column value for current row
          row[headerName] = count;
        });
        currentTime = endTime;
      }

      return {
        columns: this.tableColumns,
        data: this.tableData
      };
    },

    exportCSV() {
      try {
        let tableData = this.getDataAsTable();
        tableData = encodeURIComponent(JSON.stringify(tableData));
        let url = `${settingsModule.analyticsUrl}graphs/timeline/export?data=${tableData}`;
        window.open(url, '_blank').focus();
      } catch (err) {
        this.errorToast('Failed to export report. Please contact support!');
        this.reportError(err);
      }
    }
  },

  mounted() {
    this.drawBarGraph(this.graph);
  }
};
