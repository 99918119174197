<template>
  <div>
    <div class="barChart" v-if="retentionData">
      <el-table class="cohortTable" border :data="retentionData" style="width: 100%">
        <el-table-column fixed label="Cohort" width="200" class-name="bg-primary-50 text-gray-800">
          <template slot-scope="scope">
            <div class="bg-primary-50 text-gray-800 pl-2 py-2.5 pr-2 text-center">{{ scope.row['title'] }}</div>
          </template>
        </el-table-column>

        <el-table-column fixed label="Customers" width="140" class-name="bg-primary-50">
          <template slot-scope="scope">
            <div class="bg-secondary-200 text-gray-800 pl-2 py-2.5 pr-2 text-center">{{ scope.row['users'] }}</div>
          </template>
        </el-table-column>

        <el-table-column v-for="index in retentionData.length - 1" :key="index" :label="toPascalCase(params.form.groupDataBy) + ' ' + (index - 1)" width="100">
          <template slot-scope="scope">
            <div class="pl-2 py-2.5 pr-2 text-gray-800 text-center" :class="{ 'bg-green-100': scope.row.hasOwnProperty(index) }">{{ scope.row[index] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  props: {
    params: {
      required: true,
      type: Object
    },
    data: {
      required: true,
      type: Object
    }
  },
  components: {},
  data() {
    return {
      retentionData: null
    };
  },
  methods: {
    doRender() {
      this.units = this.data.units;
      let data = this.data.data;
      let overAllData = this.data.overAll;

      // Build cohort table Structure
      let tableData = [];
      // Add Overall Cohort
      let overAllObj = {
        title: overAllData.label,
        users: overAllData.fr
      };

      for (let j = 0; j < overAllData.retention.length; j++) {
        if (this.params.form.metric == 'percentage') {
          overAllObj[j + 1] = overAllData.retentionPerc[j].value + '%';
        } else {
          overAllObj[j + 1] = overAllData.retention[j].value;
        }
      }
      tableData.push(overAllObj);
      // Add Individual Cohorts
      for (let i = 0; i < data.length; i++) {
        // Process Table Data
        let obj = {
          title: data[i].label,
          users: data[i].fr
        };
        for (let j = 0; j < data[i].retention.length; j++) {
          if (this.params.form.metric == 'percentage') {
            obj[j + 1] = data[i].retentionPerc[j] + '%';
          } else {
            obj[j + 1] = data[i].retention[j];
          }
        }
        tableData.push(obj);
      }
      this.retentionData = tableData;
    }
  },
  mounted() {
    if (this.data) {
      this.doRender();
    }
  }
};
</script>

<style lang="scss">
.cohortTable {
  border: none;
  th,
  td {
    // border: 1px solid red;
    padding: 0px !important;

    .cell {
      padding: 0px !important;
    }
  }

  th {
    // border: 1px solid red;
    padding: 7px 10px 7px 10px !important;
    color: #444;
    text-align: center;
    font-size: 12px;
  }
}
</style>
