<template>
  <div id="retentiontPage">
    <!-- Header -->
    <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
      <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md">
        Cohort / Retention Analysis
        <div class="menuBetaTag">BETA</div>
      </div>

      <!-- Header With Filters -->
      <div class="bg-white border-b border-gray-200">
        <RetentionStepsFilter ref="retentionStepsRef"></RetentionStepsFilter>
      </div>

      <div class="px-4 py-2 bg-white" style="height: 70px">
        <el-button class="mt-2" type="primary" @click="fetchRetentionData()" :loading="fetchingGraphData">Generate Report</el-button>

        <div class="float-right">
          <!-- Group Data  -->
          <div class="inline-block mr-3 text-left">
            <div class="text-xs text-gray-400">Group Data By</div>
            <!-- Query Timing -->
            <el-select v-model="form.groupDataBy" size="small" class="w-40">
              <el-option label="Days" value="day"> </el-option>
              <el-option label="Weeks" value="week"> </el-option>
              <el-option label="Months" value="month"> </el-option>
            </el-select>
          </div>

          <!-- Metric Type -->
          <div class="inline-block mr-3 text-left">
            <div class="text-xs text-gray-400">Select Metric</div>
            <el-radio-group v-model="form.metric" size="small" plain>
              <el-radio-button value="percentage" label="percentage">% Percentage</el-radio-button>
              <el-radio-button value="count" label="count"># Count</el-radio-button>
            </el-radio-group>
          </div>

          <!-- Timing -->
          <div class="inline-block text-left">
            <div class="text-xs text-gray-400">Report Duration</div>
            <!-- Query Timing -->
            <el-select v-model="form.timingFilter" size="small" class="w-60">
              <el-option v-for="item in timingOptions" :key="item.value" :label="item.title" :value="item.value"> </el-option>
            </el-select>
            <el-date-picker class="w-20 mt-2" size="small" :editable="false" start-placeholder="Start Date" end-placeholder="End Date" :clearable="false" v-if="form.timingFilter == 'custom'" v-model="form.customTimeRange" type="daterange" format="dd-MMM-yyyy"> </el-date-picker>
          </div>

          <!-- Export -->
          <div class="inline-block text-left -mt-1" v-if="retentionData">
            <el-tooltip effect="dark" content="Export Report" placement="top-start">
              <el-button class="-mt-1" @click="exportReport" :loading="fetchingGraphData" type="info" size="small" icon="el-icon-download" plain circle></el-button>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>

    <!-- Loader -->
    <div v-if="fetchingGraphData" class="border rounded-lg overflow-hidden py-40 bg-white">
      <div class="" v-loading="true" style="min-height: 100px"></div>
      <div class="w-full text-center pt-5 text-gray-500">This may take few seconds</div>
    </div>

    <template v-if="showErrorBlock">
      <ErrorBlock></ErrorBlock>
    </template>

    <template v-else>
      <!-- Cohort Results -->
      <template v-if="!fetchingGraphData">
        <!-- Cohort Chart -->
        <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4 position-relative" v-if="retentionData">
          <div class="pinToDashboard" @click="showSelectDashboardPopup('cohort_graph')">
            <el-tooltip class="item" effect="dark" content="Pin to dashboard" placement="left">
              <i class="fa fa-thumb-tack" aria-hidden="true"></i>
            </el-tooltip>
          </div>
          <div class="bg-white pt-10 pr-4 pb-4">
            <CohortGraph :params="apiParams" :data="retentionData"></CohortGraph>
          </div>
        </div>

        <!-- Cohort Table -->
        <div class="rounded-md border overflow-hidden position-relative bg-white" v-if="retentionData">
          <div class="pinToDashboard" @click="showSelectDashboardPopup('cohort_table')">
            <el-tooltip class="item" effect="dark" content="Pin to dashboard" placement="left">
              <i class="fa fa-thumb-tack" aria-hidden="true"></i>
            </el-tooltip>
          </div>
          <CohortTable :params="apiParams" :data="retentionData"></CohortTable>
        </div>
      </template>

      <!-- No Cohort Data Message -->
      <div class="py-10 border bg-white rounded-md overflow-hidden" v-if="!fetchingGraphData && !retentionData">
        <div class="grid grid-cols-7 px-4 py-2 bg-white">
          <div class="pt-1 col-span-4">
            <div class="text-gray-700 font-bold text-3xl mb-2">Cohort Analysis</div>
            <div class="text-sm pr-4 leading-5 text-gray-400">With cohort analysis, you can group customers who have performed the same events in a given duration. You can also analyze customer's performance over time and understand how long it takes for an average customer to take an action after taking any first step.</div>
            <div class="text-sm pr-4 text-gray-500 mt-3">
              <div class="text-gray-500 font-bold">Example use-cases of cohort analysis:</div>
              <ul class="ml-3 pt-2 leading-7 text-gray-400">
                <li>Howmany days after making purchases customers come back to website/app?</li>
                <li>What is ideal period customer make purchase after viewing a product?</li>
                <li>Understand how customers are buying product B after purchasing product A.</li>
                <li>How soon do customers uninstall mobile app if they don't play game?</li>
              </ul>
            </div>
          </div>

          <div class="text-right col-span-3 rounded-md overflow-hidden" style="border: 1px solid rgb(237 237 237); opacity: 0.9; filter: alpha(opacity=40); /* msie */ background-color: #000">
            <img class="w-full" src="@/assets/images/cohort-preview.png" />
          </div>
        </div>
      </div>
    </template>

    <DashboardSelectorComponent ref="dashboardSelector" :cardType="dashboardCardPopup.cardType" :cardParams="dashboardCardPopup.cardParams"> </DashboardSelectorComponent>
  </div>
</template>
<script>
import retentionComponent from './retentionAnalysisComponent';
export default retentionComponent;
</script>
<style lang="scss" src="./retentionAnalysis.scss"></style>
