<template>
  <div>
    <div class="barChart" v-if="retentionGraphData">
      <highcharts class="eventsChart" ref="lineGraph" :options="retentionGraphData"></highcharts>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  props: {
    params: {
      required: true,
      type: Object
    },
    data: {
      required: true,
      type: Object
    }
  },
  components: {},
  data() {
    return {
      retentionGraphData: null
    };
  },
  methods: {
    doRender() {
      this.units = this.data.units;
      // let data = this.data.data;
      let overAllData = this.data.overAll;

      // Build graph structure.
      let graphData = [];
      for (let i = 0; i < overAllData.retention.length; i++) {
        // Process Graph Data
        let graphObj = this.params.form.metric == 'percentage' ? overAllData.retentionPerc[i].value : overAllData.retention[i].value;
        graphData.push(graphObj);
      }
      this.drawGraph(graphData, this.params.form.metric == 'percentage');
    },

    drawGraph(data, isPercentage) {
      let vueObj = this;
      this.retentionGraphData = {
        height: 200,
        chart: { height: 200 },
        title: {
          text: null
        },
        xAxis: {
          tickInterval: 1,
          accessibility: {
            rangeDescription: `Range: 0 to ${data.length}`
          },
          labels: {
            formatter: function () {
              return `${vueObj.toPascalCase(vueObj.params.form.groupDataBy)} ${this.value - 1}`;
            }
          }
        },

        yAxis: {
          title: {
            text: 'Returning Customers'
          },
          labels: {
            format: isPercentage ? '{value}%' : '{value}'
          }
        },

        tooltip: {
          formatter: function () {
            return `<b>${vueObj.toPascalCase(vueObj.params.form.groupDataBy)} ${this.point.x - 1}</b><br />
            ${this.point.y}${isPercentage ? '%' : ''} Returning Customers`;
          }
        },

        series: [
          {
            name: 'Retention Per ',
            data: data,
            pointStart: 1,
            showInLegend: false
          }
        ]
      };
    }
  },
  mounted() {
    if (this.data) {
      this.doRender();
    }
  }
};
</script>

<style lang="scss"></style>
